import React, {Fragment, useRef, forwardRef, useEffect, useState, useCallback, ReactNode} from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import {useBreadcrumb} from "gatsby-plugin-breadcrumb"
import throttle from "lodash.throttle"
import {isMobile} from "react-device-detect"
import axios from "axios"


// Components
import {getWindowHeight} from "../components/utility"
import LayoutBase from "../components/Layout/"
import SEO from "../components/Seo"
import Slider from "../components/HomeSlider"
import FirstMessage from "../components/page/index/FirstMessage"
import InformationList from "../components/InformationList"
import ProductDevelopment from "../components/page/index/ProductDevelopment"
import ProductDevelopmentWorks from "../components/page/index/ProductDevelopmentWorks"
import InHouseProductDevelopment from "../components/page/index/InHouseProductDevelopment"
import Information from "../components/page/index/Information"


// Style
import "../styles/pages/home.scss"


// scrollmagic の playState を gsap の playState で返す
const sideImagePlayState = (progress: any, eventType: any) => {
    switch (eventType) {
        case "start":
            return "play"
        case "end":
            return "play"
        case "leave":
            return "reverse"
        default:
            return "pause"
    }
}

/**
 * Home Page Component
 *
 * @return {*} React Element
 */
interface IHomePageDefaultProps {
    location: any,
    data: any,
}

const HomePage = (props: IHomePageDefaultProps) => {
    // ページの設定
    const pageId = "home"
    const pageTitle = ""
    const location = props.location
    const {crumbs} = useBreadcrumb({
        location: location,
        crumbLabel: "Home",
    })

    const query = useStaticQuery(graphql`
        query {
            firstMessage_BgImage: file(relativePath: {eq: "home_firstMessage_logoImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1600, quality: 90) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_parallaxBgImage: file(relativePath: {eq: "home_productDevelopment_description_img1.png"}) {
                childImageSharp {
                    fluid(maxHeight: 250, quality: 90, background: "transparent") {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_parallaxBgImage2: file(relativePath: {eq: "productDevelop_1_bg2.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1600, quality: 100, background: "transparent") {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_circuitAndBoardDesign: file(relativePath: {eq: "home_pd_side_circuitAndBoardDesign.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_machineAndMechanismDesign: file(relativePath: {eq: "home_pd_side_machineAndMechanismDesign.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_softwareDevelop: file(relativePath: {eq: "home_pd_side_softwareDevelop.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_designDevelop: file(relativePath: {eq: "home_pd_side_designDevelop.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_fabricationMountingAndAssembly: file(relativePath: {eq: "home_pd_side_fabricationMountingAndAssembly.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_evaluationTest: file(relativePath: {eq: "home_pd_side_evaluationTest.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            pd_field_sideImage_massProductionSupport: file(relativePath: {eq: "home_pd_side_massProductionSupport.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            ihpd_hems_1: file(relativePath: {eq: "inHouseProduct_hems_1.png"}) {
                childImageSharp {
                    fluid(maxWidth: 500, quality: 100, background: "transparent") {
                        ...GatsbyImageSharpFluid
                        presentationWidth
                    }
                }
            },
            directusInformation: allDirectusInformation (
                limit: 5
                sort: {
                    fields:[date]
                    order: [DESC]
                }
            ) {
                edges {
                    node {
                        directusId
                        id
                        date
                        category
                        title
                        body
                    }
                }
            },
        }
    `)
    const [windowHeight, setWindowHeight] = useState(0)
    const [postsInformation, setPostsInformation] = useState([])


    useEffect(() => {
        onWindowResize()

        // インフォメーションの記事を取得する
        // 取得制限（-1 で制限なし）
        const informationGetLimit = 5
        // 取得するフィールド
        const informationFields = "date,category,title"
        // ソート（フィールド名。-を付けると降順）
        const informationSort = "-date"

        axios
            .get(`https://cms.tranceboot.com/tranceboot-website/items/information?limit=${informationGetLimit}&fields=${informationFields}&sort=${informationSort}&status=published`, {
                withCredentials: true
            })
            .then(response => {
                setPostsInformation(response.data)
            })
            .catch(()=>{
                console.log('通信に失敗しました')
            })

        if (typeof window !== "undefined") {
            if (isMobile) {
                window.addEventListener("orientationchange", onWindowResize)
            } else {
                window.addEventListener("resize", boundWindowResize)
            }
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("orientationchange", onWindowResize)
                window.removeEventListener("resize", boundWindowResize)
            }
        }
    }, [])


    useEffect(()=> {
        console.log(postsInformation, '通信に成功しました。')
    },[postsInformation])


    // リサイズ時の処理
    const onWindowResize = () => {
        // 通常の処理

        // 遅れて取得する必要のある処理を入れる
        window.setTimeout(() => {
            // iOSで画面回転時に即時取得しても正しい値が得られないため
            let height = 0

            const getHeight = getWindowHeight()
            if (getHeight !== undefined) height = getHeight

            setWindowHeight(height)
        }, 800)
    }


    // 実行間隔を間引いたリサイズ時の処理
    const boundWindowResize = useCallback(throttle(() => {
        onWindowResize()
    }, 800), [setWindowHeight])


    return (
        <Fragment>
            <SEO pageTitle={pageTitle}/>
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
                headerCoverUp={true}
                headerSmallOfPageTop={true}
            >
                <section>
                    <div className="grid-container full">
                        <div className="grid-x">
                            <div className="cell">
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* 最初のメッセージ */}
                <section>
                    <FirstMessage
                        query={query}
                        debug={false}
                    />
                </section>
                {/* 製品開発 */}
                <section>
                    <ProductDevelopment
                        query={query}
                        windowHeight={windowHeight}
                        debug={false}
                    />
                    <ProductDevelopmentWorks/>
                </section>

                {/* 自社製品開発 */}
                <section>
                    <InHouseProductDevelopment
                        query={query}
                        windowHeight={windowHeight}
                        debug={true}
                    />
                </section>

                {/* インフォメーション */}
                <section>
                    <Information
                        query={query}
                        windowHeight={windowHeight}
                        debug={true}
                    />
                </section>

            </LayoutBase>
        </Fragment>
    )
}


export default HomePage



