import React, { useState, useEffect, useRef } from "react"
import * as PropTypes from "prop-types"
import { Tween, Timeline } from "react-gsap"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Components
import StretchedImage from "../GatsbyComponent/StretchedImage"

// Styles
import "../../styles/components/HomeSlider/SlideHems.scss"


/**
 * SlideHems Component
 * HEMS制御盤のスライド
 *
 * @param props
 * @return {*} React Element
 */
const SlideHems = (props: any) => {
    const [playState, setPlayState] = useState("stop")
    const query = useStaticQuery(graphql`
        query {
            hems_bg: file(relativePath: {eq: "home_slide_hems_bg.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
            hems_image1: file(relativePath: {eq: "home_slide_hems_img1.png"}) {
                childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)

    useEffect(() => {
        if (props.showIndex == props.index) {
            playAnimation()
        } else {
            stopAnimation()
        }
    }, [props.showIndex])

    useEffect(() => {
        if (props.isResize) {
            resizeStopAnimation()
        } else {
            if (props.showIndex == props.index) {
                playAnimation()
            }
        }
    }, [props.isResize])

    function playAnimation() {
        setPlayState("play")
    }

    function stopAnimation() {
        setPlayState("stop")
    }

    function resizeStopAnimation() {
        if (props.isResize) setPlayState("stop")
    }

    return (
        <BackgroundImage
            Tag="div"
            className="slide"
            fluid={query.hems_bg.childImageSharp.fluid}
        >
            <div id="slideHems" className="slide--gradation">
                <div className="grid-container" style={{ height: "100%", width: "100%" }}>
                    <div className="grid-x align-stretch">
                        <div className="cell">
                            <AnimationTimelines
                                playState={playState}
                                query={query}
                                {...props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}
SlideHems.propTypes = {
    index: PropTypes.number.isRequired,
    showIndex: PropTypes.number.isRequired,
    isMobileWidth: PropTypes.bool,
    isResize: PropTypes.bool,
    nextMoveCallback: PropTypes.func,
    nextMoveDelay: PropTypes.number,
}
SlideHems.defaultProps = {
    isMobileWidth: false,
    isResize: false,
    nextMoveCallback: undefined,
    nextMoveDelay: 2000,
}


/**
 * AnimationTimelines Component
 * タイムラインを切り替える
 * @param props
 * @return {*} React Element
 */
const AnimationTimelines = (props: any) => {
    if (props.isMobileWidth) {
        return <AnimationTimelineMobile {...props}/>
    }

    return <AnimationTimeline {...props}/>
}
AnimationTimelines.propTypes = {
    playState: PropTypes.string,
    query: PropTypes.any,
    index: PropTypes.number,
    showIndex: PropTypes.number,
    isMobileWidth: PropTypes.bool,
    nextMoveCallback: PropTypes.func,
    nextMoveDelay: PropTypes.number,
}


/**
 * AnimationTimelineMobile Component
 * 狭幅画面用のアニメーションタイムライン
 *
 * @param props
 * @return {*} React Element
 */
const AnimationTimelineMobile = (props: any) => {
    const [timerId, setTimerId] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (props.playState == "stop") {
            if (timerId !== undefined) {
                clearTimeout(timerId)
                setTimerId(undefined)
            }
        }
    }, [props.playState, timerId])

    function slideEnd() {
        return props.nextMoveCallback()
    }

    const completeTimeline = () => {
        const id = window.setTimeout(slideEnd, props.nextMoveDelay)
        setTimerId(id)
    }

    return (
        <Timeline
            wrapper={
                <div style={{ height: "100vh", width: "100%", position: "relative" }}/>
            }
            target={
                <div className="image1-container animation-vertical-loop">
                    <StretchedImage
                        fluid={props.query.hems_image1.childImageSharp.fluid}
                        className="image1"
                    />
                </div>
            }
            paused={props.playState !== "play"}
            playState={props.playState}
            onComplete={completeTimeline}
        >
            <Tween
                from={{
                    top: "20%",
                    left: 0,
                    right: 0,
                    opacity: "0",
                }}
                to={{
                    top: "15%",
                    opacity: "1.0",
                }}
                duration={1}
                ease="Power4.easeOut"
            />

            <Tween
                wrapper={
                    <ol
                        style={{
                            margin: 0,
                            padding: 0,
                            listStyle: "none",
                            position: "absolute",
                            bottom: "25vh",
                            textAlign: "center",
                        }}
                    />
                }
                staggerFrom={{
                    opacity: 0,
                }}
                staggerTo={{
                    opacity: 1,
                }}
                ease="Power4.easeOut"
                duration={1}
                stagger={0.3}
            >
                <li><h1>HEMS制御盤</h1></li>
                <li>蓄電池の充電/給電のスケジュール機能。</li>
                <li>停電を検知して蓄電池や太陽光発電の電気を<br className="common-text-breakline-mobile"/>指定した系統（壁付けコンセント）へ自動供給する機能を搭載。</li>
                <li>6種類のエネルギー計測に対応したHEMS機器です。</li>
            </Tween>
        </Timeline>
    )
}
AnimationTimelineMobile.propTypes = {
    index: PropTypes.number,
    showIndex: PropTypes.number,
    isMobileWidth: PropTypes.bool,
    playState: PropTypes.string,
    nextMoveCallback: PropTypes.func,
    nextMoveDelay: PropTypes.number,
    query: PropTypes.any,
}


/**
 * AnimationTimeline Component
 * 広幅画面用のアニメーションタイムライン
 *
 * @param props
 * @return {*} React Element
 */
const AnimationTimeline = (props: any) => {
    const [timerId, setTimerId] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (props.playState == "stop") {
            if (timerId !== undefined) {
                clearTimeout(timerId)
                setTimerId(undefined)
            }
        }
    }, [props.playState, timerId])

    function slideEnd() {
        return props.nextMoveCallback()
    }

    const completeTimeline = () => {
        const id = window.setTimeout(slideEnd, props.nextMoveDelay)
        setTimerId(id)
    }

    return (
        <Timeline
            wrapper={
                <div style={{ height: "100vh", width: "100%", position: "relative" }}/>
            }
            target={
                <div className="image1-container animation-vertical-loop">
                    <StretchedImage
                        fluid={props.query.hems_image1.childImageSharp.fluid}
                        className="image1"
                    />
                </div>
            }
            paused={props.playState !== "play"}
            playState={props.playState}
            onComplete={completeTimeline}
        >
            <Tween
                from={{
                    left: "-100%",
                    opacity: 0,
                }}
                to={{
                    left: "-50%",
                    opacity: 1.0,
                }}
                duration={1}
                ease="Power4.easeOut"
            />
            <Tween
                wrapper={
                    <ol
                        style={{
                            margin: 0,
                            padding: 0,
                            listStyle: "none",
                            position: "absolute",
                            top: "35%",
                            left: "50%",
                            right: "8%",
                            textAlign: "left",
                        }}
                    />
                }
                staggerFrom={{
                    opacity: 0,
                }}
                staggerTo={{
                    opacity: 1,
                }}
                ease="Power4.easeOut"
                duration={1}
                stagger={0.3}
            >
                <li><h1>HEMS制御盤</h1></li>
                <li>蓄電池の充電/給電のスケジュール機能。</li>
                <li>停電を検知して蓄電池や太陽光発電の電気を指定した系統（壁付けコンセント）へ自動供給する機能を搭載。</li>
                <li>6種類のエネルギー計測に対応したHEMS機器です。</li>
            </Tween>

        </Timeline>
    )
}
AnimationTimeline.propTypes = {
    index: PropTypes.number,
    showIndex: PropTypes.number,
    isMobileWidth: PropTypes.bool,
    playState: PropTypes.string,
    nextMoveCallback: PropTypes.func,
    nextMoveDelay: PropTypes.number,
    query: PropTypes.any,
}


export default SlideHems