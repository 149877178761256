import React, { Fragment } from "react"
import * as PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons"


/**
 * Arrow component
 *
 * @return React Element {*}
 */
interface IArrowProps {
    type: string,
    style?: any,
    onClick: any,
}

const Arrow = (props: IArrowProps) => {
    let icon = faChevronLeft
    let className = "home-slider__arrow-base home-slider__arrow-prev"
    let label = "前へ"

    if (props.type == "next") {
        icon = faChevronRight
        className = "home-slider__arrow-base home-slider__arrow-next"
        label = "次へ"
    }


    return (
        <div
            className={className}
            style={{
                ...props.style,
            }}
            onClick={props.onClick}
        >
            <span
                className="utility-visually-hidden"
                data-testid="icon-label"
            >
                {label}
            </span>
            <span className="home-slider__icon" aria-hidden="true">
                <FontAwesomeIcon icon={icon} size="2x" fixedWidth/>
            </span>
        </div>
    )
}

Arrow.propTypes = {
    type: PropTypes.oneOf(["prev", "next"]).isRequired,
    style: PropTypes.any,
    onClick: PropTypes.any.isRequired,
}


/**
 * 複数のクリックを一つにまとめるHOC
 *
 * https://qiita.com/koedamon/items/26536a97d8794cb85a86
 */
function joinClickHandler(WrappedComponent: any) {
    return class clickHandler extends React.Component<IArrowProps> {
        static propTypes = {
            type: PropTypes.oneOf(["prev", "next"]).isRequired,
            style: PropTypes.any,
            onClick: PropTypes.any.isRequired,
        }

        timer: any

        constructor(props: any) {
            super(props)
            this.timer = null
        }

        handleClick(e: any, callBack: any) {
            e.persist() //eventオブジェクトを切り離す

            clearTimeout(this.timer)
            this.timer = setTimeout(callBack, 200, e)
        }

        // ラップされるコンポーネントに、引き継いだpropsとハンドラを渡す
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    handleClick={this.handleClick.bind(this)}
                />
            )
        }
    }
}


const ArrowButton = joinClickHandler(Arrow)

export default ArrowButton

