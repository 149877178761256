import React, {useState} from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image"
import {Controller, Scene} from "react-scrollmagic";
import {Timeline, Tween} from "react-gsap";


/**
 * FirstMessage Component
 * 最初のメッセージ
 */
interface IFirstMessageDefaultProps {
    query: any,
    debug: boolean,
}

const FirstMessage = (props: IFirstMessageDefaultProps) => {
    const indicators = props.debug

    const [lottieStopped, setLottieStopped] = useState(false)

    return (
        <Controller>
            <Scene
                triggerElement="#firstMessage"
                triggerHook={0.7}
                offset={100}
                duration={600 - 100}
                indicators={indicators}
            >
                {(progress: any, event: any) => {
                    return (
                        <Timeline
                            target={
                                <div id="firstMessage" className="grid-container full">
                                    <div className="grid-x align-center-middle text-center">
                                        <div className="cell">
                                            <div className="pages-home-first-message-content">
                                                <div className="grid-container">
                                                    <div className="grid-x">
                                                        <div className="cell">
                                                            <h1>私たちは<br className="common-text-breakline-mobile"/>"モノづくり"の会社です
                                                            </h1>
                                                            <p>企画・提案、ハード / ソフトウェア開発、試作、国内外量産まで、モノづくり全般の業務をおこなっています。</p>
                                                            <p>「試作を数台だけ作りたい」「企画しているサービスに合うハードとソフトを作りたい」など</p>
                                                            <p>モノづくりにおける多様なニーズにお答えします。</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pages-home-first-message-bglogo">
                                        <BackgroundImage
                                            Tag="div"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            fluid={props.query.firstMessage_BgImage.childImageSharp.fluid}
                                            backgroundColor={`#fff`}
                                        />
                                    </div>
                                </div>
                            }
                            progress={progress}
                            paused={true}
                        >
                            <Tween
                                from={{
                                    color: `rgb(255, 255, 255)`,
                                }}
                                to={{
                                    color: `rgb(0, 0, 0)`,
                                }}
                            />
                        </Timeline>
                    )
                }}
            </Scene>
        </Controller>
    )
}
FirstMessage.propTypes = {
    debug: PropTypes.bool,
}
FirstMessage.defaultProps = {
    debug: false,
}


export default FirstMessage