import React, {ReactNode} from "react";


/**
 * Works
 * 開発例
 */
const ProductDevelopmentWorks = () => {
    return (
        <div id="productDevelopmentWorks" className="grid-container full">
            <div className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="cell small-12">
                                <div className="productDevelopmentWorks__title">
                                    <h2>開発事例</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell">
                    <div className="grid-container">
                        <div className="grid-x grid-margin-x align-stretch">
                            <WorkCard title="エネルギー計測ユニット" className="cell small-12 medium-6 large-4">
                                自社製品開発の「HEMS制御盤」を応用したエネルギー計測ユニットの開発
                                <WorkCardDevice>
                                    <li>16ビットマイコン</li>
                                    <li>電力計測IC</li>
                                    <li>WiFi</li>
                                    <li>RTC</li>
                                </WorkCardDevice>
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">機械・機構設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                    <li><span className="common-text-tag">デザイン開発</span></li>
                                    <li><span className="common-text-tag">製作・実装・組立</span></li>
                                    <li><span className="common-text-tag">評価試験</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="電子機器" className="cell small-12 medium-6 large-4">
                                商用向け電子機器のデザイン開発
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">デザイン開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="液晶端末" className="cell small-12 medium-6 large-4">
                                ARM11プロセッサ（SAMSUNG S3C6410）を使った液晶端末の開発
                                <WorkCardDevice>
                                    <li>ARM11プロセッサ（SAMSUNG S3C6410）</li>
                                    <li>4.3インチ / 7インチLCD</li>
                                    <li>WiFi</li>
                                    <li>RTC</li>
                                </WorkCardDevice>
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">機械・機構設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                    <li><span className="common-text-tag">デザイン開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="情報収集機器" className="cell small-12 medium-6 large-4">
                                ルネサス（SH-2A）のマイコンを使った情報収集機器の開発
                                <WorkCardDevice>
                                    <li>ルネサス（SH-2A）</li>
                                    <li>RTC</li>
                                    <li>Ethernet</li>
                                </WorkCardDevice>
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="非接触計測機器" className="cell small-12 medium-6 large-4">
                                Microchip8ビットマイコン（PIC18）を使った計測機器の開発
                                <WorkCardDevice>
                                    <li>Microchip8ビットマイコン（PIC18）</li>
                                    <li>RTC</li>
                                    <li>Ethernet</li>
                                    <li>計測用センサ</li>
                                </WorkCardDevice>
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="USB無線通信機器" className="cell small-12 medium-6 large-4">
                                Microchip8ビットマイコン（PIC18）を使ったUSB無線通信機器
                                <WorkCardDevice>
                                    <li>Microchip8ビットマイコン（PIC18）</li>
                                    <li>USB</li>
                                    <li>429MHz帯の無線モジュール</li>
                                </WorkCardDevice>
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">機械・機構設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                    <li><span className="common-text-tag">デザイン開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="ウィジェットアプリ" className="cell small-12 medium-6 large-4">
                                JNI（Java Native Interface）を用いた無線通信機器の制御
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="情報表示アプリ" className="cell small-12 medium-6 large-4">
                                Android(2.2)上で動作するアプリケーションの開発
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                            <WorkCard title="AC-DC変換の電源回路" className="cell small-12 medium-6 large-4">
                                蓄電池充電向けのAC-DC変換の電源回路の開発
                                <WorkCardCategory>
                                    <li><span className="common-text-tag">回路・基板設計</span></li>
                                    <li><span className="common-text-tag">ソフトウェア開発</span></li>
                                </WorkCardCategory>
                            </WorkCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


/**
 * WorkCard component
 *
 * @param {string} title - タイトル名
 * @param {string} className - スタイルクラス名
 * @param {object} style? - インラインスタイル
 * @param {ReactNode} children? - 子要素
 * @return {*} React Element
 */
interface IWorkCardDefaultProps {
    title: string,
    className?: string,
    style?: object,
    children?: ReactNode,
}

const WorkCard = (props: IWorkCardDefaultProps) => {
    return (
        <div className={props.className} style={props.style}>
            <div className="card pages-home-pd-works-card">
                <div className="card-divider">{props.title}</div>
                <div className="card-section">
                    {props.children}
                </div>
            </div>
        </div>
    )
}


/**
 * WorkCardCategory component
 *
 * @param {string} className - スタイルクラス名
 * @param {object} style? - インラインスタイル
 * @param {ReactNode} children? - 子要素
 * @return {*} React Element
 */
interface IWorkCardCategoryDefaultProps {
    className?: string,
    style?: object,
    children?: ReactNode,
}

const WorkCardCategory = (props: IWorkCardCategoryDefaultProps) => {
    return (
        <div className={`pages-common-quote pages-common-quote-style--bradyfun ${props.className}`} style={props.style}>
            <p className="__title">開発分野</p>
            <ul className="common-list-flat-sentence">
                {props.children}
            </ul>
        </div>
    )
}


/**
 * WorkCardDevice component
 *
 * @param {string} className - スタイルクラス名
 * @param {object} style? - インラインスタイル
 * @param {ReactNode} children? - 子要素
 * @return {*} React Element
 */
interface IWorkCardDeviceDefaultProps {
    className?: string,
    style?: object,
    children?: ReactNode,
}

const WorkCardDevice = (props: IWorkCardDeviceDefaultProps) => {
    return (
        <div className={`pages-home-work-card-device pages-common-quote ${props.className}`} style={props.style}>
            <p className="__title">主なデバイス</p>
            <ul className="common-list-marker-microchip" style={{marginLeft: "24px"}}>
                {props.children}
            </ul>
        </div>
    )
}


export default ProductDevelopmentWorks