import React, {useCallback, useEffect, useRef, useState} from "react";
import BackgroundImage from "gatsby-background-image"
import {isMobile} from "react-device-detect";
import throttle from "lodash.throttle";
import {Controller, Scene} from "react-scrollmagic";


// Components
import PD_fieldContent from "./PD_fieldContent";


// SVG
// @ts-ignore
import CircuitAndBoardDesignIcon from "../../../images/circuitAndBoardDesign_icon.svg";
// @ts-ignore
import MachineAndMechanismDesignIcon from "../../../images/machineAndMechanismDesign_icon.svg"
// @ts-ignore
import SoftwareDevelopIcon from "../../../images/softwareDevelop_icon.svg"
// @ts-ignore
import DesignDevelopIcon from "../../../images/designDevelop_icon.svg"
// @ts-ignore
import FabricationMountingAndAssemblyIcon from "../../../images/fabricationMountingAndAssembly_icon.svg"
// @ts-ignore
import EvaluationTestIcon from "../../../images/evaluationTest_icon.svg"
// @ts-ignore
import MassProductionSupportIcon from "../../../images/massProductionSupport_icon.svg"


/**
 * ProductDevelopment Component
 * 製品開発
 *
 * @param props
 * @return {*} React Element
 */
interface IProductDevelopmentDefaultProps {
    query: any,
    windowHeight?: number,
    debug?: boolean,
}

const ProductDevelopment = (props: IProductDevelopmentDefaultProps) => {
    const circuitAndBoardDesignEl = useRef(null)
    const machineAndMechanismDesignEl = useRef(null)
    const softwareDevelopEl = useRef(null)
    const designDevelopEl = useRef(null)
    const fabricationMountingAndAssemblyEl = useRef(null)
    const evaluationTestEl = useRef(null)
    const massProductionSupportEl = useRef(null)

    const [componentHeight, setComponentHeight] = useState<number>(0)
    const [productDevelopmentFieldDuration, setProductDevelopmentFieldDuration] = useState<number>(0)


    useEffect(() => {
        onWindowResize()

        if (typeof window !== "undefined") {
            if (isMobile) {
                window.addEventListener("orientationchange", onWindowResize)
            } else {
                window.addEventListener("resize", boundWindowResize)
            }
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("orientationchange", onWindowResize)
                window.removeEventListener("resize", boundWindowResize)
            }
        }
    }, [])


    useEffect(() => {
        let _windowHeight = props.windowHeight
        let _componentHeight = componentHeight
        let _duration = 0

        if (_windowHeight === undefined || _windowHeight === null) _windowHeight = 0
        if (_componentHeight === undefined || _componentHeight === null) _componentHeight = 0

        _duration = _componentHeight - _windowHeight
        setProductDevelopmentFieldDuration(_duration)
    }, [props.windowHeight, componentHeight])


    // リサイズ時の処理
    const onWindowResize = () => {
        // 通常の処理

        // 遅れて取得する必要のある処理を入れる
        window.setTimeout(() => {
            // iOSで画面回転時に即時取得しても正しい値が得られないため
            setComponentHeight(fieldContentTotalHeight())
        }, 800)
    }

    // 実行間隔を間引いたリサイズ時の処理
    const boundWindowResize = useCallback(throttle(() => {
        onWindowResize()
    }, 800), [setComponentHeight])

    // ref の高さを取得して返す
    const getFieldContentHeight = (ref: any) => {
        let height = 0

        if (ref.current === null) {
            return height
        }

        height = ref.current.clientHeight
        return height
    }

    // 各分野の高さの合計を返す
    const fieldContentTotalHeight = () => {
        let height = 0

        height += getFieldContentHeight(circuitAndBoardDesignEl)
        height += getFieldContentHeight(machineAndMechanismDesignEl)
        height += getFieldContentHeight(softwareDevelopEl)
        height += getFieldContentHeight(designDevelopEl)
        height += getFieldContentHeight(fabricationMountingAndAssemblyEl)
        height += getFieldContentHeight(evaluationTestEl)
        height += getFieldContentHeight(massProductionSupportEl)
        height += 100 //開発事例のmargin-top分

        return height
    }

    return (
        <div id="productDevelopment" className="grid-container full">

            {/* 説明 */}
            <div id="productDevelopment_description" className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div className="pages-home-description grid-x align-center-middle text-center">
                            <div className="cell small-12">
                                <h1 className="__title">製品開発<span>受託・請負開発</span></h1>
                                <p>プロダクト全体から一部アウトソースの開発まで、ハードウェア / ソフトウェアの受託・請負開発をおこなっています。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell">
                    <BackgroundImage
                        Tag="div"
                        className="productDevelopment_description__image1"
                        fluid={props.query.pd_parallaxBgImage.childImageSharp.fluid}
                        backgroundColor="transparent"
                    />
                </div>
            </div>

            {/* 分野紹介 */}
            <div id="productDevelopment_field" className="grid-x">
                <div className="cell">
                    <div className="pages-home-field-container">

                        {/* 回路・基板設計 */}
                        <PD_fieldContent
                            ref={circuitAndBoardDesignEl}
                            id="productDevelopment_field_circuitAndBoardDesign"
                            title="回路・基板設計"
                            subTitle="circuit and board design"
                            imageQuery={props.query.pd_field_sideImage_circuitAndBoardDesign.childImageSharp.fluid}
                            iconComponent={<CircuitAndBoardDesignIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">入手性、コストに配慮した部品選定、量産効率を考慮した設計を実現します。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                回路設計
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">デジタル回路設計</li>
                                                        <li className="common-text-tag">アナログ回路設計</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                プリント基板設計
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">電源基板</li>
                                                        <li className="common-text-tag">アナログ基板</li>
                                                        <li className="common-text-tag">特性インピーダンス基板</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* 機械・機構設計 */}
                        <PD_fieldContent
                            ref={machineAndMechanismDesignEl}
                            id="productDevelopment_field_machineAndMechanismDesign"
                            title="機械・機構設計"
                            subTitle="machine and mechanism design"
                            imageQuery={props.query.pd_field_sideImage_machineAndMechanismDesign.childImageSharp.fluid}
                            iconComponent={<MachineAndMechanismDesignIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">産業機器や意匠性が重要な商品など、用途に合わせて最適な設計を実現します。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                筐体設計
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">板金筐体</li>
                                                        <li className="common-text-tag">樹脂筐体</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* ソフトウェア開発 */}
                        <PD_fieldContent
                            ref={softwareDevelopEl}
                            id="productDevelopment_field_softwareDevelop"
                            title="ソフトウェア開発"
                            subTitle="software develop"
                            imageQuery={props.query.pd_field_sideImage_softwareDevelop.childImageSharp.fluid}
                            iconComponent={<SoftwareDevelopIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">組込み系ソフトウェアから、各種アプリケーションの開発・保守をおこなっています。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                組込みソフトウェア開発
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">組込みOS</li>
                                                        <li className="common-text-tag">C / C++言語</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                アプリケーション
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">Windowsアプリケーション</li>
                                                        <li className="common-text-tag">Androidアプリケーション</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                Webフロントエンド開発
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">JavaScript / TypeScript</li>
                                                        <li className="common-text-tag">PHP</li>
                                                        <li className="common-text-tag">HTML / CSS</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                Webバックエンド開発
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">PHP</li>
                                                        <li className="common-text-tag">SQL</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* デザイン開発 */}
                        <PD_fieldContent
                            ref={designDevelopEl}
                            id="productDevelopment_field_designDevelop"
                            title="デザイン開発"
                            subTitle="design develop"
                            imageQuery={props.query.pd_field_sideImage_designDevelop.childImageSharp.fluid}
                            iconComponent={<DesignDevelopIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">製品・サービスのコンセプトに適したデザイン要素の開発・保守をおこなっています。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                プロダクトデザイン
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">電子機器の筐体</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                Webサイト
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">企業サイト</li>
                                                        <li className="common-text-tag">特設・プロモーションサイト</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                ソフトウェアUI
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">モバイルアプリケーション</li>
                                                        <li className="common-text-tag">デスクトップアプリケーション</li>
                                                        <li className="common-text-tag">キオスク端末</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                DTP
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">リーフレット / パンフレット</li>
                                                        <li className="common-text-tag">取扱説明書</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                パッケージデザイン
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">化粧箱</li>
                                                        <li className="common-text-tag">ブリスターパッケージ</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                CGイメージ製作
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">製品イメージカット</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* 製作・実装・組立 */}
                        <PD_fieldContent
                            ref={fabricationMountingAndAssemblyEl}
                            id="productDevelopment_field_fabricationMountingAndAssembly"
                            title="製作・実装・組立"
                            subTitle="fabrication, mounting and assembly"
                            imageQuery={props.query.pd_field_sideImage_fabricationMountingAndAssembly.childImageSharp.fluid}
                            iconComponent={<FabricationMountingAndAssemblyIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">試作品や展示用デモ機など少量製作に最適な、1台から対応する小ロット生産をおこなっています。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                試作製作
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">部材調達</li>
                                                        <li className="common-text-tag">基板製造 / 電子部品実装</li>
                                                        <li className="common-text-tag">樹脂筐体 / パーツ製造：3Dプリント（積層 / 光造形）
                                                        </li>
                                                        <li className="common-text-tag">樹脂筐体 / パーツ製造：簡易金型</li>
                                                        <li className="common-text-tag">組立</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* 評価試験 */}
                        <PD_fieldContent
                            ref={evaluationTestEl}
                            id="productDevelopment_field_evaluationTest"
                            title="評価試験"
                            subTitle="evaluation test"
                            imageQuery={props.query.pd_field_sideImage_evaluationTest.childImageSharp.fluid}
                            iconComponent={<EvaluationTestIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">JIS規格などの各種規格に基づいた評価試験をおこなっています。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                信頼性評価試験
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">環境試験</li>
                                                        <li className="common-text-tag">機械的試験</li>
                                                        <li className="common-text-tag">安全試験</li>
                                                        <li className="common-text-tag">電気的試験</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>

                        {/* 量産支援 */}
                        <PD_fieldContent
                            ref={massProductionSupportEl}
                            id="productDevelopment_field_massProductionSupport"
                            title="量産支援"
                            subTitle="mass production support"
                            imageQuery={props.query.pd_field_sideImage_massProductionSupport.childImageSharp.fluid}
                            iconComponent={<MassProductionSupportIcon/>}
                            windowHeight={props.windowHeight}
                        >
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="cell small-12 medium-12 large-5">
                                        <p className="tween">商品に最適な工場を国内 / 国外から選定し量産立ち上げのサポートをおこなっています。</p>
                                    </div>
                                    <div className="cell small-12 medium-12 medium-offset-0 large-6 large-offset-1">
                                        <ul className="common-list-marker-check-square">
                                            <li>
                                                量産支援
                                                <div className="pages-common-quote pages-common-quote-style--bradyfun">
                                                    <p className="__title">主な内容</p>
                                                    <ul className="common-list-flat-sentence">
                                                        <li className="common-text-tag">部材調達（国内 / 国外）</li>
                                                        <li className="common-text-tag">工場選定（国内 / 国外）</li>
                                                        <li className="common-text-tag">量産立会 / 調整</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </PD_fieldContent>
                    </div>
                    <Controller>
                        <Scene
                            triggerElement="#productDevelopment_field"
                            triggerHook={0}
                            duration={productDevelopmentFieldDuration}
                            pin={{
                                pushFollowers: true,
                                spacerClass: "#productDevelopmentBg",
                            }}
                            indicators={props.debug}
                        >
                            <div id="productDevelopmentBg" className="pages-home-field-bg"/>
                        </Scene>
                    </Controller>
                </div>
            </div>
        </div>
    )
}
ProductDevelopment.defaultProps = {
    windowHeight: 0,
    debug: false,
}


export default ProductDevelopment