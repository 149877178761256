import React, {useEffect, useState} from "react";
import Img from "gatsby-image"


/**
 * InHouseProductDevelopment Component
 * 自社製品開発
 *
 * @param props
 * @return {*} React Element
 */
interface IInHouseProductDevelopmentDefaultProps {
    query: any,
    windowHeight?: number,
    debug?: boolean,
}

const InHouseProductDevelopment = (props: IInHouseProductDevelopmentDefaultProps) => {
    const [componentHeight, setComponentHeight] = useState(0)

    useEffect(() => {

    }, [props.windowHeight])

    return (
        <div id="inHouseProductDevelopment" className="grid-container full">
            {/* 説明 */}
            <div id="inHouseProductDevelopment_description" className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div className="pages-home-description grid-x align-center-middle text-center">
                            <div className="cell small-12">
                                <h1 className="__title">自社製品開発</h1>
                                <p>電気、ガス、水道エネルギーに関する機器の技術研究・開発をおこなっています。<br/>
                                    市場ニーズに合う成果は自社ブランドやOEM生産向け製品として展開しています。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* HEMS制御盤 */}
            <div id="inHouseProductDevelopment_hemsControlPanel" className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div className="grid-x align-middle">
                            <div className="cell small-12 medium-6">
                                <Img
                                    fluid={props.query.ihpd_hems_1.childImageSharp.fluid}
                                    alt="HEMS分電盤のイメージ"
                                    className="inHouseProductDevelopment_hemsControlPanel__image1"
                                />
                            </div>
                            <div className="cell small-12 medium-6">
                                <h1 className="__title">HEMS制御盤</h1>
                                <p>蓄電池の充電/給電のスケジュール機能。停電を検知して蓄電池や太陽光発電の電気を指定した系統（壁付けコンセント）へ自動供給する機能を搭載。<br
                                    className="common-text-breakline-mobile"/>6種類のエネルギー計測に対応したHEMS機器です。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
InHouseProductDevelopment.defaultProps = {
    windowHeight: 0,
    debug: false,
}


export default InHouseProductDevelopment