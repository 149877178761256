import React, {forwardRef, useEffect, useState} from "react";
import {getElementClientHeight} from "../../utility";
import {Controller, Scene} from "react-scrollmagic";
import {Timeline, Tween} from "react-gsap";
import StretchedImage from "../../GatsbyComponent/StretchedImage";


/**
 * PD_fieldContent Component
 * 製品開発の分野紹介コンポーネント
 *
 * @param id {string} required - コンポーネントのID
 * @param title {string} required - コンポーネントのタイトル
 * @param imageQuery {graphql} required - graphQLのクエリー
 * @param iconComponent {component} required - インポートしたSVGのコンポーネント
 * @param windowHeight {number} - windowの高さ。親が値を持っている場合に指定する。主にuseEffectの更新トリガーとして使用している
 * @param ref required - コンポーネントのref
 * @param animationEnable: react-scrollmagicのSceneコンポーネントを動作させる
 * @return {*} React Element
 */
interface IPD_fieldContentDefaultProps {
    id: string,
    title: string,
    subTitle: string,
    imageQuery: any,
    iconComponent: any,
    windowHeight?: number,
    children: React.ReactNode | Function,
    debug?: boolean,
}

type TPD_fieldContentRef = HTMLDivElement

const PD_fieldContent = forwardRef<TPD_fieldContentRef, IPD_fieldContentDefaultProps>((props, ref) => {
    const _id = `#${props.id}`
    const triggerHook = 0.5         // scrollmagic のイベント発生位置
    const indicators = props.debug  // scrollmagic の start / end 位置を表示する（デバック用）

    const [componentHeight, setComponentHeight] = useState<number | undefined>(0)
    //const [isPlaySideimageTimeline, setIsPlaySideimageTimeline] = useState<boolean>(false)
    //const [isShowSideimageTimeline, setIsShowSideimageTimeline] = useState<boolean>(false)
    let isPlaySideimageTimeline = false
    let isShowSideimageTimeline = false

    useEffect(() => {
        setComponentHeight(getElementClientHeight(props.id))
    }, [props.windowHeight])

    const sideImageTimeline = (type: string) => {
        if (isPlaySideimageTimeline) return

        switch (type) {
            case "start":
            case "end":
                if (!isShowSideimageTimeline) {
                    isShowSideimageTimeline = true
                    return "play"
                }
                break
            case "leave":
                if (isShowSideimageTimeline) {
                    isShowSideimageTimeline = false
                    return "reverse"
                }
                break
            default:
                return "pause"
        }
    }

    // const updateIsPlaySideimageTimeline = (prop: boolean) => {
    //     // 別コンポーネントでのレンダリング中にsetStateを呼び出すと警告が出るので、その回避策
    //     // https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
    //     // https://qiita.com/koichiba/items/e0d772022baf94d48419
    //     useEffect(() => {
    //         setIsPlaySideimageTimeline(prop)
    //     }, [setIsPlaySideimageTimeline])
    // }

    return (
        <div
            id={props.id}
            className="pages-home-pd-field-content"
            ref={ref}
        >
            <div className="grid-container">
                <div className="grid-x">
                    <div className="cell small-12 medium-8">
                        <div className="pages-home-pd-field-content__header">
                            <div className="icon">
                                <div className="animation-vertical-loop">
                                    <Controller>
                                        <Scene
                                            triggerElement={_id}
                                            triggerHook={triggerHook}
                                            classToggle="pages-home-pd-field-content__field-icon--toggle"
                                            duration={componentHeight}
                                            indicators={indicators}
                                        >
                                            <div className="pages-home-pd-field-content__field-icon">
                                                {props.iconComponent}
                                            </div>
                                        </Scene>
                                    </Controller>
                                </div>
                            </div>
                            <div className="title">
                                <Controller>
                                    <Scene
                                        triggerElement={_id}
                                        triggerHook={triggerHook}
                                        duration={componentHeight}
                                        indicators={indicators}
                                    >
                                        {() => (
                                            <h2>{props.title}<span>{props.subTitle}</span></h2>
                                        )}
                                    </Scene>
                                </Controller>
                            </div>
                        </div>
                        <div className="pages-home-pd-field-content__body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <Controller>
                <Scene
                    triggerElement={_id}
                    triggerHook={triggerHook}
                    duration={componentHeight}
                    indicators={indicators}
                >
                    {(progress: any, event: any) => (
                        <Timeline
                            target={
                                <div className="pages-home-pd-field-content__sideimage">
                                    <div style={{height: "100%", width: "100%"}}>
                                        <StretchedImage
                                            fluid={props.imageQuery}
                                            imgStyle={{width: "auto"}}
                                        />
                                    </div>
                                </div>
                            }
                            playState={sideImageTimeline(event.type)}
                            paused={true}
                            onStart={() => {
                                isPlaySideimageTimeline = true
                                // updateIsPlaySideimageTimeline(true)
                            }}
                            onComplete={() => {
                                isPlaySideimageTimeline = false
                                // updateIsPlaySideimageTimeline(false)
                            }}
                        >
                            <Tween
                                from={{
                                    transform: "translate(100%, 0)",
                                    opacity: 0,
                                }}
                                to={{
                                    transform: "translate(0, 0)",
                                    opacity: 1,
                                }}
                                duration={0.8}
                                delay={0.5}
                                ease="Power4.easeInOut"
                            />
                        </Timeline>
                    )}
                </Scene>
            </Controller>
        </div>
    )
})
PD_fieldContent.defaultProps = {
    windowHeight: 0,
    debug: false,
}


export default PD_fieldContent


