// Windowの高さを返す
export const getWindowHeight = (): number | undefined => {
    let height = undefined

    if (typeof window !== "undefined") {
        height = window.innerHeight
    }

    return height
}


// idで指定した要素の高さを返す
export const getElementClientHeight = (id: string): number | undefined => {
    let height = undefined

    if (typeof document !== "undefined") {
        const element = document.getElementById(id)
        if (element !== undefined && element) {
            height = element.clientHeight
        }
    }

    return height
}