import React, {useEffect, useState} from "react";
import {Link} from "gatsby";


// Components
import InformationList from "../../InformationList";


/**
 * Information Component
 * インフォメーション
 *
 * @param {*} props
 * @return {*} React Element
 */
interface IInformation {
    query: any,
    windowHeight?: number,
    debug?: boolean,
}

const Information = (props: IInformation) => {
    const informationUrl = "/information"
    const [componentHeight, setComponentHeight] = useState(0)

    useEffect(() => {

    }, [props.windowHeight])

    return (
        <div id="information" className="grid-container full">
            <div className="grid-x">
                <div className="cell">
                    <div className="grid-container">
                        <div id="information_description"
                             className="pages-home-description grid-x align-center-middle text-center">
                            <div className="cell small-12">
                                <h1 className="__title">Information</h1>
                            </div>
                        </div>
                        <div id="information_list" className="grid-x">
                            <InformationList
                                posts={props.query.directusInformation.edges}
                                slug={informationUrl}
                                className="cell"
                            />
                            <Link to={informationUrl} className="button show-list-button">すべて表示する</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Information