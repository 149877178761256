import Image from "gatsby-image"
import * as PropTypes from "prop-types"


/**
 * StretchedImage Component
 * HeightとWidthが可変なgatsby-image
 *
 * @param props
 * @return {*} React Element
 */
const StretchedImage = (props: any) => {
    let normalizedProps = props

    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                height: "100%",
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        }
    }

    return <Image {...normalizedProps} />
}

StretchedImage.propTypes = {
    fluid: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    fadeIn: PropTypes.bool,
    durationFadeIn: PropTypes.number,
    title: PropTypes.string,
    alt: PropTypes.string,
    crossOrigin: PropTypes.string,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    style: PropTypes.object,
    imgStyle: PropTypes.object,
    placeholderClassName: PropTypes.string,
    backgroundColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    onLoad: PropTypes.func,
    onStartLoad: PropTypes.func,
    onError: PropTypes.func,
    Tag: PropTypes.string,
    objectFit: PropTypes.string,
    objectPosition: PropTypes.string,
    loading: PropTypes.string,
    critical: PropTypes.bool,
    fluidImages: PropTypes.array,
    draggable: PropTypes.bool,
    itemProp: PropTypes.string,
}


export default StretchedImage
