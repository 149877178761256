import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import * as PropTypes from "prop-types"
import throttle from "lodash.throttle"
import Slick from "react-slick"
import styled from "@emotion/styled"
import { isMobile } from "react-device-detect"


// Components
import ArrowButton from "./Arrow"
import SlideProductDevelopment from "./SlideProductDevelopment"
import SlideInHouseProduct from "./SlideInHouseProduct"
import SlideHems from "./SlideHems"


// Styles
import "../../styles/vendor/slick/slick.scss"
import "../../styles/components/HomeSlider/slick-theme.scss"


/**
 * SliderWrap
 * スライドを画面全体に広げるワ―パー
 */
const SliderWrap = styled.div`
  .slick-slider {
        height: 100vh;
        .slick-list {
            .slick-track {
                .slick-slider.slide_item {
                    height: 100vh;
                    width: 100vw;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
    }
`


/**
 * HomeSlider component
 *
 */
interface IHomeSliderProps {
}


interface IHomeSliderState {
    index: number,
    clientHeight: number,
    windowWidth: number,
    isMobileWidth: boolean,
    isResize: boolean,
}


class HomeSlider extends Component<IHomeSliderProps, IHomeSliderState> {
    static propTypes = {}

    sliderRef: any
    sliderElement: any
    resizeTo: any
    boundWindowResize: any
    prev: any
    next: any


    constructor(props: IHomeSliderProps) {
        super(props)
        this.state = {
            index: 0,
            clientHeight: 0,
            windowWidth: 0,
            isMobileWidth: false,
            isResize: false,
        }
        this.sliderRef = React.createRef()
        this.sliderElement = undefined
        this.resizeTo = undefined
        this.boundWindowResize = throttle(this.onWindowResize.bind(this), 800)
        this.prev = this.onClickPrev.bind(this)
        this.next = this.onClickNext.bind(this)
    }

    componentDidMount() {
        this.updateClientHeight()
        this.updateWindowWidth()

        if (typeof window !== "undefined") {
            // iOSだとスクロールでresizeイベントが発生するためイベントを別ける
            if (isMobile) {
                window.addEventListener("orientationchange", this.boundWindowResize)
            } else {
                window.addEventListener("resize", this.boundWindowResize)
            }
        }

        this.sliderElement = ReactDOM.findDOMNode(this)
    }

    componentDidUpdate(prevProps: Readonly<IHomeSliderProps>, prevState: Readonly<IHomeSliderState>, snapshot?: any): void {
        if (this.state.windowWidth !== prevState.windowWidth) {
            this.checkMobileWidth()
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.boundWindowResize)
        window.removeEventListener("orientationchange", this.boundWindowResize)
    }

    onWindowResize() {
        this.updateClientHeight()
        this.updateWindowWidth()
        this.resizeSlide()
    }

    onClickPrev() {
        if (this.sliderRef !== null) {
            this.sliderRef.slickPrev()
        }
    }

    onClickNext() {
        if (this.sliderRef !== null) {
            this.sliderRef.slickNext()
        }
    }

    updateClientHeight() {
        if (this.sliderRef !== undefined || null) {
            this.setState({ clientHeight: this.sliderRef.innerSlider.list.clientHeight })
        }
    }

    updateWindowWidth() {
        if (typeof window !== "undefined") {
            this.setState({ windowWidth: window.innerWidth })
        }
    }

    resizeSlide() {
        if (this.resizeTo) clearTimeout(this.resizeTo)

        this.setState({ isResize: true })
        this.resizeTo = setTimeout(() => {
            this.setState({ isResize: false })
        }, 600)
    }

    checkMobileWidth() {
        if (640 >= this.state.windowWidth) {
            this.setState({ isMobileWidth: true })
        } else {
            this.setState({ isMobileWidth: false })
        }
    }

    render() {
        const sliderSettings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            fade: true,
            autoplaySpeed: 6000,
            pauseOnHover: true,
            afterChange: (current: number) => {
                this.setState({ index: current })
            },
            beforeChange: () => {
            },
        }
        const arrowTop = -(this.state.clientHeight / 2)
        // 表示時間 1秒 = 1000
        const delayTime = 8000

        return (
            <div className="home-slider-container">
                <SliderWrap>
                    <Slick
                        ref={c => this.sliderRef = c}
                        {...sliderSettings}
                    >
                        <SlideProductDevelopment
                            index={0}
                            showIndex={this.state.index}
                            isMobileWidth={this.state.isMobileWidth}
                            isResize={this.state.isResize}
                            nextMoveCallback={() => this.onClickNext()}
                            nextMoveDelay={delayTime}
                        />
                        <SlideHems
                            index={1}
                            showIndex={this.state.index}
                            isMobileWidth={this.state.isMobileWidth}
                            isResize={this.state.isResize}
                            nextMoveCallback={() => this.onClickNext()}
                            nextMoveDelay={delayTime}
                        />
                    </Slick>
                </SliderWrap>
                <div className="home-slider-controller grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="cell">
                            <ArrowButton
                                type="prev"
                                style={{
                                    display: this.state.isMobileWidth ? "none" : "block",
                                    top: `${arrowTop}px`,
                                }}
                                onClick={this.prev}
                            />
                            <ArrowButton
                                type="next"
                                style={{
                                    display: this.state.isMobileWidth ? "none" : "block",
                                    top: `${arrowTop}px`,
                                }}
                                onClick={this.next}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default HomeSlider
